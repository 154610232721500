jQuery(document).ready(function ($) {
	
  $("#hikashop_show_tabular_new_comment,#hikashop_show_tabular_comment").hide();
  
	$(document).on('click', '.variant_line_tab', function () {
		
		var code = $(this).attr('data-code');
		
		$('.parent_description').slideUp();
		$('.child_description').slideUp();
		$('.child_description[data-code="'+code+'"]').slideDown();
		
		$('.parent_ensavoirplus').slideUp();
		$('.child_ensavoirplus').slideUp();
		$('.child_ensavoirplus[data-code="'+code+'"]').slideDown();
		
		
	});
  
  
  console.log('test');
  
});
